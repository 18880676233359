<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="450"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3 w-crud-form">       
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="insumo.category_id"
                            :items="categories"
                            label="Seleccione categoría"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"                 
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="insumo.unit_id"
                            :items="units"
                            label="Seleccione unidad"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"                 
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="insumo.name" label="Descripción" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="insumo.cost" label="Costo" type="number" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
            </template>
        </div>
        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>    
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState} from 'vuex'
import SuppliesApi from '../../apis/Supplies';
import VueSweetalert2 from 'vue-sweetalert2';
import Vue from 'vue';

Vue.use(VueSweetalert2);

export default {
    name: 'CreateUpdate',
    props: ["devolverData"],
    data() {
        return{
            drawer: false,
            titleForm: null,
            action: null,
            menu1: false,
            has_user: false,
            insumo: {
                id: null,
                category_id: null,
                unit_id: null,
                name: null,
                cost: null,
                stock: null,
                minimum_stock: null,
                status: null
            },
            categorias: null
        }
    },
    computed: {
        ...mapState('categories', [
            'categories'
        ]),
        ...mapState('units', [
            'units'
        ]),
    },
    methods: {
        ...mapActions('categories', [
            'getCategories',
        ]),
        ...mapActions('notifications', [
            'store_notification'
        ]),
        async showForm(title, action, insumo = null) {
            await this.clearData();
            this.titleForm = title
            this.action = action
            if (insumo) {
                this.setInsumo(insumo)
            }
            
            this.categorias = this.categories;
            this.drawer = !this.drawer

        },
        setInsumo(insumo){
            this.insumo.id = insumo.id;
            this.insumo.category_id = insumo.category_id;
            this.insumo.unit_id = insumo.unit_id;
            this.insumo.name = insumo.name;
            this.insumo.cost = insumo.cost;
            this.insumo.stock = insumo.stock;
            this.insumo.minimum_stock = insumo.minimum_stock;
            this.insumo.status = insumo.status;
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.insumo.id = null;
            this.insumo.category_id = null;
            this.insumo.unit_id = null;
            this.insumo.name = null;
            this.insumo.cost = null;
            this.insumo.stock = null;
            this.insumo.minimum_stock = null;
            this.insumo.status = null;
            this.clearErrors();
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        clearErrors() {
            /*Object.keys(this.employeeForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });*/
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleStore({dispatch}) {
            console.log(this.insumo);
            await SuppliesApi.store(this.insumo).then(value => {
                //Vue.swal('Hello Vue world!!!');
                this.store_notification({
                    type: 'success',
                    message: 'El registro se realizó de forma correcta.'
                });
                console.log(value);
                this.devolverData(value);
                //  this.closeForm()
            }).catch(error => {
                this.store_notification({
                    type: 'error',
                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                });
            });
            //await this.store(this.productForm)
            //this.closeForm()
        },
        async handleUpdate() {
            console.log(this.insumo);
            await SuppliesApi.update(this.insumo).then(value => {
                this.store_notification({
                    type: 'success',
                    message: 'La actualización se realizó de forma correcta.'
                });
                console.log(value);
                this.devolverData(value);
                this.closeForm()
            }).catch(error => {
                this.store_notification({
                    type: 'error',
                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                });
            });
        }, 
        handleCancel() {
            this.closeForm()
        },
    }
}
</script>

<style scoped>

</style>