<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="600"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3 w-crud-form">       
            <template>
                <!--<v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="insumo.category_id"
                            :items="categories"
                            label="Seleccione categoría"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"                 
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="insumo.unit_id"
                            :items="units"
                            label="Seleccione unidad"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"                 
                        ></v-select>
                    </v-col>
                </v-row>-->
                <!--<v-row>
                    <v-col cols="12">
                        <v-text-field v-model="insumo.name" label="Descripción" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>-->
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="cantidad" label="Cantidad" type="number" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            v-model="selectedRows"
                            :headers="headers"
                            :items="ingredientes"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="40vh"
                            show-select
                            :single-select="false"
                            @item-selected="handleSelectItem"
                            :options.sync="options"
                            :server-items-length="ingredientes.length"
                        >
                            <template v-slot:top>
                                <span
                                    class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table"
                                >
                                    Todos los ingredientes
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.name="{ item }" style="text-align: start;">
                                <span class="font-weight-medium">{{ item.supply.name }}</span>

                            </template>

                            <template v-slot:item.id="{ item }">
                                <span class="font-weight-medium">{{ item.quantity }} {{ item.unit.name }}</span>

                            </template>
                            <template v-slot:item.supply="{ item }">
                                <span class="font-weight-medium">{{ item.supply.cost }} {{ item.unit.name }}</span>

                            </template>

                            <template v-slot:item.recipe_id="{ item }">
                                <span class="font-weight-medium">{{ parseFloat(item.quantity) * parseFloat(item.supply.cost) * parseFloat(cantidad  )}}</span>

                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
        </div>
        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Comprar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>    
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ArrayTools from "../../../helpers/ArrayTools";
import ProductionApi from '../../../apis/Production';

export default {
    name: 'OrdenRapida',
    props: ["devolverData"],
    data() {
        return {
            drawer: false,
            titleForm: null,
            cantidad: 0.0,//null,
            ingredientes: [],
            selectedRows: [],
            new_stocks: [],
            headers: [
                { text: "NOMBRE", value: "name", width: 90 },
                { text: "CANTIDAD", value: "id", width: 20 },
                { text: "PRECIO", value: "supply", width: 20 },
                { text: "TOTAL", value: "recipe_id", width: 20 },
            ],
        }
    },
    methods: {
        ...mapActions('notifications', [
            'store_notification'
        ]),
        async showForm(title, action, receta = null) {
            //await this.clearData();
            console.log('esta es la receta');
            console.log(receta);
            this.titleForm = title+' ('+receta.name+')';
            this.action = action
            if (receta) {
                this.ingredientes = receta.ingredients;
                //this.setInsumo(insumo)
            }
            console.log(this.ingredientes);
            //this.categorias = this.categories;
            this.drawer = !this.drawer

        },
        handleCancel() {
            this.closeForm()
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.ingredientes = [];
            this.new_stocks = [];
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        handleSelectItem(selected) {
            if (selected.value) {
                this.selectedRows.push(selected.item);
            } else {
                let index_found = ArrayTools.getIndexById(
                this.selectedRows,
                selected.item.id
                );
                if (index_found > -1) {
                this.selectedRows.splice(index_found, 1);
                }
            }
            
            //this.showOptionCrud();
        },
        handleUpdate() {
            console.log(this.selectedRows);
            if (this.cantidad) {
                this.new_stocks = [];
                for (let i = 0; i < this.selectedRows.length; i++) {
                const element = {
                    new_stock: this.cantidad * this.selectedRows[i].quantity,
                    supply_id: this.selectedRows[i].supply_id
                };
                this.new_stocks.push(element);
                
            }
            }
            
            console.log(this.new_stocks);
            let filters = {
                new_stocks: this.new_stocks
            }

            ProductionApi.saveNewInventory(filters).then((response) => {
                this.store_notification({
                    type: 'success',
                    message: 'El nuevo stock se registró con éxito.'
                });
                this.closeForm();
                //this.fillNewStock()
            }).catch( (error) => {
                this.store_notification({
                    type: 'error',
                    message: 'Se detectó un error al guardar nuevo stock.'
                });
            });
        }
    }
}
</script>

<style>

</style>