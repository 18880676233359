import Api from './Api'

const END_POINT = 'supplies'

export default {
    async get_all(filters){
        console.log(filters);
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'supplies-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request);
    },
    async store(supplies){
        return await Api.post(`${END_POINT}`, supplies);
    },
    async update(supplie){
        return await Api.put(`${END_POINT}/${supplie.id}`,supplie);
    },
    async change_status(id){
        let url = 'supply-change-status/'+id;
        return await Api.get(url);

    },
    async delete(){

    }
}