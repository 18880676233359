<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Recetas</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <!--<v-menu rounded="0" offset-y>-->
                        <v-btn
                                color="#023145"
                                class="white--text float-right"
                                @click="handleShowCreate"
                            >
                                Nuevo
                            </v-btn>

                       <!--<v-list>
                            <v-list-item link>
                                <v-list-item-title @click="handleShowCreate"
                                >Registrar manualmente</v-list-item-title
                                >
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item link>
                                <v-list-item-title @click="handleUpload"
                                >Registrar desde archivo</v-list-item-title
                                >
                            </v-list-item>
                        </v-list>-->
                    <!--</v-menu>-->
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pt-1">
                    <v-text-field
                        v-model="search_product"
                        label="Buscar"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                        @keydown="handleInputSearchByEnter"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="5" class="pt-1">
                    <v-btn class="w-btn-search" height="40" @click="handleSearch">
                        Buscar
                    </v-btn>
                </v-col>
                <!--<v-col cols="3" class="pt-1">
                    <v-btn
                        v-if="this.$hasPermision('productos.filters')"
                        dark
                        color="#023145"
                        outlined
                        width="140"
                        class="float-right"
                        @click="handleShowFilters"
                    >
                        <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
                        Ver Filtros
                    </v-btn>
                    <v-btn
                        dark
                        color="green"
                        class="float-right mr-2"
                        @click="handleDownload"
                    >
                        <v-icon class="mr-1" size="16">fas fa-download</v-icon>
                        Descargar
                    </v-btn>
                </v-col>-->
            </v-row>
            <v-row>
                <v-col cols="3" v-for="(recipe, index) in recetas" :key="recipe.id">
                    <v-card>
                        <v-card-title class="pt-2 pb-2 recipe-name">
                            <span class="subtitle-2">{{ recipe.name }}</span>
                            <v-spacer></v-spacer>
                            <v-icon color="green"
                                size="18"
                                class="pr-2"
                                @click="handleOrdenRapida(recipe)">
                                fas fa-list
                            </v-icon>
                            <v-icon color="blue"
                                size="18"
                                class="pr-2"
                                @click="handleEdit(recipe)">
                                fas fa-pen
                            </v-icon>
                            <v-icon color="error"
                                size="18"
                                @click="handleDeleteRecipe(recipe, index)">
                                far fa-times-circle
                            </v-icon>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pt-1 pb-1 pl-1 pr-1 list-ingredients">
                            <v-list-item two-line 
                            v-for="ingredient in recipe.ingredients" :key="'i'+ingredient.id"
                            class="item-ingredient">
                            <v-list-item-content class="pt-1 pb-1">
                                <v-list-item-title>
                                    <span class="caption" v-if="ingredient.supply">
                                        {{ ingredient.supply.name }}
                                    </span>
                                </v-list-item-title>
                                <!--<v-list-item-subtitle>
                                    <span v-if="ingredient.supply" class="product-fullname">
                                        ({{ ingredient.supply.name }})
                                    </span>
                                </v-list-item-subtitle>-->
                                <v-list-item-subtitle class="item-quantity">
                                    <v-icon size="14" class="mr-2" color="indigo">
                                        fas fa-tags
                                    </v-icon>
                                    <span class="item-quantity caption font-italic font-weight-bold">
                                        {{ ingredient.quantity }}
                                    </span>
                                    <span v-if="ingredient.unit" 
                                        class="ml-1 item-quantity caption font-italic font-weight-bold">
                                        {{ ingredient.unit.name }}
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <orden-rapida ref="ordenRapida" :devolver-data="handleAddAnalysis"></orden-rapida>

    </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import router from "@/router/index";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import CreateUpdate from '../../../components/insumos/CreateUpdate'
import ProductionApi from '../../../apis/Production';
import OrdenRapida from './OrdenRapida';

export default {
    name: "Recetas",
    components: {
        OfficeLogin,
        OrdenRapida
    },
    watch: {
        option: {
            handler() {

            },
            deep: true
        }
    },
    data() {
        return {
            itemsBreadcrumbs: [
                {
                text: "Recetas",
                disabled: true,
                href: "",
                },
            ],
            recetas: []
        }
    },
    methods: {
        ...mapActions("products", [
            "getProducts",
            "change_status",
            "delete",
            "runCleanProducts",
            "selectProduct",
            "emptyProducts",
            "changeFilterPaginated",
        ]),
        handleShowCreate() {
            router.push("/app/logistica/datos-recetas");
        },
        handleEdit(recipe) {
            //let product = this.selectedRows[0];
            //this.selectedRows = [];
            //this.showOptionCrud();
            console.log(recipe);
            this.selectProduct(recipe);
            router.push("/app/logistica/datos-recetas");
        },
        handleOrdenRapida(recipe){
            /*let supply = this.selectedRows[0]
            this.selectedRows = []*/
            //this.showOptionCrud()
            this.$refs.ordenRapida.showForm('Lista de compra', 'update', recipe);
        },
        loadData(){
            ProductionApi.listRecetas().then((value) =>{
                console.log(value);
                this.recetas = value.data;
                console.log(this.recetas);
            });
        }
    },
    created() {
        this.emptyProducts();
        this.selectProduct(null);
        this.loadData();
    },
    destroyed() {
        this.emptyProducts();
    },
}

</script>

<style scoped>
.section-categories {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, sans-serif !important;
}

.header-table-products {
  vertical-align: baseline !important;
  padding-top: 5px !important;
}

.v-data-table-header > tr > th {
  color: #263238 !important;
  vertical-align: baseline !important;
  padding-top: 5px !important;
}



.item-ingredient{
    border-bottom: 1px solid #e0e0e0;
    min-height: 50px !important;
}

.recipe-name{
    background-color: #c1c1c1;
}

.item-quantity{
    color: #4CAF50;
}

.list-ingredients{
    height: 200px;
    overflow-y: auto;
}

.product-fullname{
    font-size: .7rem;
}
</style>